import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const headerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } }
};

const Header = () => (
  <motion.header initial="hidden" animate="visible" variants={headerVariants}>
    <nav>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/skills">Skills</Link>
      <Link to="/portfolio">Projects</Link>
      <Link to="/contact">Contact</Link>
    </nav>
  </motion.header>
);

export default Header;
