import React from 'react';
import { motion } from 'framer-motion';
import developerImage from './assets/person.jpg'; // Add your developer image here
import projectImage from './assets/background-w.jpg'; // Add a project image here
import Portfolio from './components/Portfolio';

const homeVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } }
};

const Home = () => (
  <motion.section className="home" initial="hidden" animate="visible" variants={homeVariants}>
    <AboutSection />
    <Portfolio/>
  </motion.section>
);

const AboutSection = () => (
    <div className="about-section">
      <div className="about-content">
        <div className="about-text">
         {/* <img src={developerImage} alt="Developer" className="developer-image" /> */}
          <div className='about-text'>
          <p>Hello! I'm a Full Stack Developer with 13 years of experience, currently leading a team of talented developers. I've worked with companies like Samsung and Philips, and I hold an AWS Associate Solutions Architect Certification.</p>
          <p>My expertise includes Full Stack Development (ReactJS), AWS Solutions Architecture, and Django. I'm also experienced in database management, Android development, and project management. I have a passion for mentoring and have acted as a Mentor in multiple Corporate to College Projects for tier 2 College Graduates. Additionally, I've been the President of the Samsung Corporate Toastmasters Club, enhancing my public speaking skills.</p>
          </div> 
        </div>
      </div>
      <div className="about-image"></div>
    </div>
  );

export default Home;
