import React from 'react';
import PortfolioItem from '../components/PortfolioItem';
import spotify from '../assets/spotify.png';
import imdb from '../assets/imdb.png';

const Portfolio = () => {
  const projects = [
    {
      title: 'IMDB Movies',
      description: 'List of popular , upcoming & top-rated movies ',
      thumbnail: imdb,
      s3Link: 'http://imdb-rakesh.s3-website.ap-south-1.amazonaws.com/',
      githubLink: 'https://github.com/your-repo-1',
    },
    {
      title: 'Spotify Music Analysis',
      description: 'Analysis of the music listening pattern of Spotify',
      thumbnail: spotify,
      s3Link: 'http://my-spotify-app.s3-website.ap-south-1.amazonaws.com/',
      githubLink: 'https://github.com/your-repo-2',
    },
    {
      title: 'Project 3',
      description: 'Description of project 3',
      thumbnail: 'https://via.placeholder.com/300',
      s3Link: 'https://your-s3-link-3.com',
      githubLink: 'https://github.com/your-repo-3',
    },
    {
      title: 'Project 4',
      description: 'Description of project 4',
      thumbnail: 'https://via.placeholder.com/300',
      s3Link: 'https://your-s3-link-4.com',
      githubLink: 'https://github.com/your-repo-4',
    },
    {
      title: 'Project 5',
      description: 'Description of project 5',
      thumbnail: 'https://via.placeholder.com/300',
      s3Link: 'https://your-s3-link-5.com',
      githubLink: 'https://github.com/your-repo-5',
    }
  ];

  return (
    <div className="portfolio">
      <h2>Portfolio</h2>
      <div className="portfolio-grid">
        {projects.map((project, index) => (
          <PortfolioItem
            key={index}
            title={project.title}
            description={project.description}
            thumbnail={project.thumbnail}
            s3Link={project.s3Link}
            githubLink={project.githubLink}
          />
        ))}
      </div>
    </div>
  );
};

export default Portfolio;
