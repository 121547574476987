import React from 'react';
import { motion } from 'framer-motion';

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const PortfolioItem = ({ title, description, thumbnail, s3Link, githubLink }) => (
  <motion.div className="portfolio-item" initial="hidden" animate="visible" variants={itemVariants}>
    <img src={thumbnail} alt={title} className="portfolio-thumbnail" />
    <div className="portfolio-content">
      <h3>{title}</h3>
      <p>{description}</p>
      <a href={s3Link} target="_blank" rel="noopener noreferrer">View Project</a>
      <a href={githubLink} target="_blank" rel="noopener noreferrer">View Code</a>
    </div>
  </motion.div>
);

export default PortfolioItem;
