import React from 'react';
import { motion } from 'framer-motion';

const aboutVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } }
};

const workHistory = [
  { company: 'Samsung', role: 'Full Stack Developer', duration: '2015-2020', description: 'Worked on various projects involving full stack development and led a team of developers.' },
  { company: 'Philips', role: 'Android Application Developer', duration: '2010-2015', description: 'Developed Android applications and collaborated with cross-functional teams to deliver high-quality products.' },
  { company: 'Startup A', role: 'Software Engineer', duration: '2007-2010', description: 'Worked in a fast-paced environment, contributing to various software development projects.' },
  { company: 'Startup B', role: 'Junior Developer', duration: '2005-2007', description: 'Assisted in developing software solutions and gained valuable experience in the tech industry.' },
  { company: 'Tech Corp', role: 'Intern', duration: '2004-2005', description: 'Completed an internship, working on real-world projects and gaining industry experience.' },
];

const About = () => (
  <motion.section className="about" initial="hidden" animate="visible" variants={aboutVariants}>
    <div className="about-content">
      <h1>About Me</h1>
      <p>Hello! I'm a <strong>Full Stack Developer</strong> with 13 years of experience, currently leading a team of talented developers. I've worked with companies like Samsung and Philips, and I hold an AWS Associate Solutions Architect Certification.</p>
      <p>My expertise includes Full Stack Development (ReactJS), AWS Solutions Architecture, and Django. I'm also experienced in database management, Android development, and project management. I have a passion for mentoring and have acted as a Mentor in multiple Corporate to College Projects for tier 2 College Graduates. Additionally, I've been the President of the Samsung Corporate Toastmasters Club, enhancing my public speaking skills.</p>
      <h2>Work History</h2>
      <div className="timeline">
        {workHistory.map((job, index) => (
          <div className="timeline-item" key={index}>
            <h3>{job.company}</h3>
            <h4>{job.role} <span>({job.duration})</span></h4>
            <p>{job.description}</p>
          </div>
        ))}
      </div>
    </div>
  </motion.section>
);

export default About;
