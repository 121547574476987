import React from 'react';
import { motion } from 'framer-motion';
import { FaReact, FaAws, FaPython, FaDatabase, FaAndroid, FaProjectDiagram, FaChalkboardTeacher, FaMicrophone } from 'react-icons/fa';

const skillsVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 1 } }
};

const Skills = () => (
  <motion.section className="skills" initial="hidden" animate="visible" variants={skillsVariants}>
    <h2>Skills</h2>
    <div className="skills-category primary">
      <h3>Primary Skills</h3>
      <ul>
        <li style={{ color: '#61DAFB' }}><FaReact /> Full Stack Development (ReactJS)</li>
        <li style={{ color: '#FF9900' }}><FaAws /> AWS Solutions Architect</li>
        <li style={{ color: '#3776AB' }}><FaPython /> Django</li>
      </ul>
    </div>
    <div className="skills-category">
      <h3>Secondary Skills</h3>
      <ul>
        <li style={{ color: '#4DB33D' }}><FaDatabase /> Database Management</li>
        <li style={{ color: '#3DDC84' }}><FaAndroid /> Android Development</li>
        <li style={{ color: '#6C757D' }}><FaProjectDiagram /> Project Management</li>
      </ul>
    </div>
    <div className="skills-category">
      <h3>Additional Skills</h3>
      <ul>
        <li style={{ color: '#FFD700' }}><FaChalkboardTeacher /> Mentorship</li>
        <li style={{ color: '#FF6347' }}><FaMicrophone /> Toastmasters Public Speaking</li>
      </ul>
    </div>
  </motion.section>
);

export default Skills;
